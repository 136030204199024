@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles for light and dark mode backgrounds */
body {
  @apply bg-cover bg-center bg-no-repeat transition-colors duration-500 ease-in-out min-h-screen;
  background-size: cover;
  background-image: url('./assets/56E3A1E8-2F2B-4C57-97FF-B6EA4898080E.webp');
  background-attachment: fixed;
}

.dark body {
  @apply text-white;
  background-image: url('./assets/FA1E45D6-9853-4C77-B73B-890C8D902981.webp');
  transition: background-image 0.5s ease-in-out, color 0.5s ease-in-out;
}

.content-box {
  @apply bg-white bg-opacity-70 dark:bg-opacity-50 dark:bg-gray-800 p-6 rounded-lg shadow-lg;
}

.pt-16 {
  padding-top: 4rem;
}

@media (min-width: 768px) {
  .pt-16 {
    padding-top: 4rem; /* 64px for larger screens */
  }
}
